import React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function KeyboardTotem({ onKeyboardChange }) {
  return (
    <Keyboard
      inputName="name"
      theme={"hg-theme-default hg-layout-default totem"}
      onChange={onKeyboardChange}
      layout={{
        default: [
          "1 2 3 4 5 6 7 8 9 0 {bksp}",
          "q w e r t y u i o p",
          "a s d f g h j k l",
          "z x c v b n m",
          "{space}",
        ],
      }}
      display={{
        // "{bksp}": "🔙",
        "{bksp}": "✖️",
        "{space}": " ",
      }}
      buttonTheme={[
        {
          class: "totem-keyboard",
          buttons:
            "1 2 3 4 5 6 7 8 9 0 q w e r t y u i o p a s d f g h j k l z x c v b n m {bksp} {space}",
        },
        {
          class: "totem-back-space",
          buttons: "{bksp} {space}",
        },
      ]}
      maxLength={14}
    />
  );
}
