import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import {
  setInLocoAnswer,
  setFormAnswer,
  setIdentification,
  clearCheckoutTotem,
} from "../ducks/checkoutSlice";
import { PhoneInput } from "../components/NumberInput";
import CheckoutServerError from "./CheckoutServerError";
import { Form, Spinner } from "react-bootstrap";
import NumpadTotem from "./Selfcheckout/NumpadTotem";
import { domScrollToBottom } from "../utils/domScrollTo";
import documentDisplay from "../utils/documentDisplay";
import useGoTo from "../hooks/useGoTo";
import { clearCart } from "../ducks/shoppingCartSlice";
import { setActiveGroupId } from "../ducks/productsSlice";
import KeyboardTotem from "./Selfcheckout/KeyboardTotem";

const STEP_TakingHome = "STEP_TakingHome";
const STEP_PersonalData = "STEP_PersonalData";
const STEP_CPFQuestion = "STEP_CPFQuestion";
const STEP_CPFNumber = "STEP_CPFNumber";
const STEP_Table = "STEP_Table";

function TakingHome({ step, moveStep }) {
  const dispatch = useDispatch();

  if (step !== STEP_TakingHome) return null;

  const setTakingHome = (value) => {
    dispatch(
      setInLocoAnswer({
        name: "isTakingHome",
        value,
      })
    );
    moveStep(1);
  };

  return (
    <div>
      <p className="py-5 totem-header">Embalar para viagem?</p>
      <div className="d-flex justify-content-between my-5">
        <Button
          className="w-100 totem-checkout-button"
          variant="secondary"
          onClick={() => setTakingHome(true)}
        >
          Embalar para viagem
          <br />
          <LocalMallIcon />
        </Button>
        <div className="p-2" />
        <Button
          className="w-100 totem-checkout-button"
          variant="shop"
          onClick={() => setTakingHome(false)}
        >
          Vou comer aqui
          <br />
          <FastfoodIcon />
        </Button>
      </div>
      <Button
        className="totem-checkout-back-button mt-5"
        variant="outline-dark"
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function PersonalData({ step, moveStep, isSelfcheckoutTotem }) {
  const dispatch = useDispatch();
  const { name, telephone } = useSelector((state) => state.checkout);

  if (step !== STEP_PersonalData) return null;

  const nameError =
    name.length > 0 && name.length < 2 ? "Mínimo 2 letras" : null;

  if (!isSelfcheckoutTotem) {
    return (
      <PersonalDataNameTel
        moveStep={moveStep}
        name={name}
        telephone={telephone}
        nameError={nameError}
        dispatch={dispatch}
      />
    );
  }

  const disabled = name.length < 2;

  const onKeyboardChange = (input) => {
    dispatch(
      setFormAnswer({
        name: "name",
        value: input.trimStart(),
      })
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  const onBack = (event) => {
    event.preventDefault();
    onKeyboardChange("");
    moveStep(false);
  };

  return (
    <div>
      <p className="py-5 totem-header">
        {name || "Como gostaria de ser chamado?"}
      </p>
      <KeyboardTotem onKeyboardChange={onKeyboardChange} />
      <div className="d-flex justify-content-between my-5">
        <Button
          className="totem-checkout-back-button"
          variant="outline-dark"
          onClick={onBack}
        >
          Voltar
        </Button>
        <div className="p-2" />
        <Button
          type="submit"
          className="w-100 totem-checkout-button"
          variant="shop"
          disabled={disabled}
          onClick={onSubmit}
        >
          {nameError || "Confirmar"}
        </Button>
      </div>
    </div>
  );
}

function PersonalDataNameTel({
  moveStep,
  name,
  telephone,
  nameError,
  dispatch,
}) {
  const digit3 = telephone.length > 2 ? telephone.substring(2, 3) : null;
  const phoneError =
    telephone.length === 0 || (digit3 === "9" && telephone.length === 11)
      ? null
      : "Celular inválido";

  const disabled = name.length < 2 || telephone.length !== 11;

  const onNameChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "name",
        value: event.target.value,
      })
    );

  const onTelephoneChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "telephone",
        value: event.target.value,
      })
    );

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  return (
    <div>
      <p className="py-5 totem-header">Seu dados</p>
      <Form onSubmit={onSubmit}>
        <TextField
          autoFocus
          fullWidth={true}
          variant="outlined"
          type="text"
          label="Nome"
          error={nameError ? true : false}
          helperText={nameError}
          value={name}
          onChange={onNameChange}
          maxLength={64}
          required
        />
        <TextField
          fullWidth={true}
          className="mt-4"
          variant="outlined"
          label="Celular com DDD"
          error={phoneError ? true : false}
          helperText={phoneError}
          value={telephone}
          onChange={onTelephoneChange}
          InputProps={{
            inputComponent: PhoneInput,
          }}
          required
        />
        <Button
          type="submit"
          className="w-100 p-3 mt-4"
          variant="shop"
          disabled={disabled}
        >
          Confirmar
        </Button>
      </Form>
      <Button
        className="p-3 mt-5"
        variant="outline-dark"
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function CPFQuestion({ step, moveStep }) {
  const dispatch = useDispatch();

  if (step !== STEP_CPFQuestion) return null;

  const onIdentificationEnableChange = (value) => {
    dispatch(
      setFormAnswer({
        name: "isIdentificationEnable",
        value,
      })
    );
    moveStep(value ? 1 : 2);
  };

  return (
    <div>
      <p className="py-5 totem-header">CPF na nota?</p>
      <div className="d-flex justify-content-between my-5">
        <Button
          className="w-100 totem-checkout-button"
          variant="secondary"
          onClick={() => onIdentificationEnableChange(false)}
        >
          Não
          <br />
          <ClearIcon />
        </Button>
        <div className="p-2" />
        <Button
          className="w-100 totem-checkout-button"
          variant="shop"
          onClick={() => onIdentificationEnableChange(true)}
        >
          Sim
          <br />
          <CheckIcon />
        </Button>
      </div>
      <Button
        className="totem-checkout-back-button mt-5"
        variant="outline-dark"
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function CPFNumber({ step, moveStep }) {
  const dispatch = useDispatch();
  const { identification, identificationError } = useSelector(
    (state) => state.checkout
  );

  if (step !== STEP_CPFNumber) return null;

  const onKeyboardChange = (input) => {
    dispatch(setIdentification(input));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  const onBack = (event) => {
    event.preventDefault();
    dispatch(setIdentification(""));
    moveStep(false);
  };

  const disabled = !identification || !!identificationError;

  return (
    <div>
      <p className="py-5 totem-header">
        {identification ? documentDisplay(identification) : "CPF ou CNPJ"}
      </p>
      <NumpadTotem onKeyboardChange={onKeyboardChange} />
      <div className="d-flex justify-content-between my-5">
        <Button
          className="totem-checkout-back-button"
          variant="outline-dark"
          onClick={onBack}
        >
          Voltar
        </Button>
        <div className="p-2" />
        <Button
          type="submit"
          className="w-100 totem-checkout-button"
          variant="shop"
          disabled={disabled}
          onClick={onSubmit}
        >
          {identificationError || "Confirmar"}
        </Button>
      </div>
    </div>
  );
}

function Table({ step, moveStep }) {
  const dispatch = useDispatch();
  const { inLocoLocation } = useSelector((state) => state.checkout);

  if (step !== STEP_Table) return null;

  const onInLocoLocationChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "inLocoLocation",
        value: event.target.value,
      })
    );

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  const disabled = !inLocoLocation;

  return (
    <div>
      <p className="py-5 totem-header">Entregar os produtos em qual mesa?</p>
      <div>
        <Form onSubmit={onSubmit}>
          <TextField
            fullWidth={true}
            helperText="Ex: Mesa 12 ou Balcão"
            variant="outlined"
            label=""
            value={inLocoLocation}
            onChange={onInLocoLocationChange}
            required
            inputProps={{ maxLength: 30 }}
          />
          <Button
            type="submit"
            className="w-100 totem-checkout-button mt-4"
            variant="shop"
            disabled={disabled}
          >
            Confirmar
          </Button>
        </Form>
      </div>
      <Button
        className="totem-checkout-back-button mt-5"
        variant="outline-dark"
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

export default function QuestionCheckout({
  isSelfcheckoutTotem,
  table,
  takingHome,
  personalData,
  cpfQuestion,
  dispatchSubmitCheckout,
}) {
  const goTo = useGoTo();
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector((state) => state.checkout);
  const [step, setStep] = React.useState("");
  const allSteps = [];

  React.useEffect(() => {
    setTimeout(domScrollToBottom, 500);
  }, []);

  if (table) {
    allSteps.push(STEP_Table);
  }

  if (takingHome) {
    allSteps.push(STEP_TakingHome);
  }

  if (personalData) {
    allSteps.push(STEP_PersonalData);
  }

  if (cpfQuestion) {
    allSteps.push(STEP_CPFQuestion);
    allSteps.push(STEP_CPFNumber);
  }

  const startSteps = () => {
    if (allSteps.length === 0) {
      dispatchSubmitCheckout();
      return;
    }

    setStep(allSteps[0]);
  };

  const moveStep = (next) => {
    const currentIndex = allSteps.indexOf(step);
    if (next === false) {
      if (currentIndex === 0) {
        dispatch(clearCheckoutTotem());
        setStep("");
        return;
      }

      setStep(allSteps[currentIndex - 1]);
      return;
    }

    if (next === 1 || next === 2) {
      const nextIndex = currentIndex + next;
      if (nextIndex === allSteps.length) {
        dispatchSubmitCheckout();
        return;
      }

      setStep(allSteps[nextIndex]);
      return;
    }
  };

  const onCancel = () => {
    dispatch(setActiveGroupId(0));
    dispatch(clearCart());
    dispatch(clearCheckoutTotem());
    goTo("/");
  };

  if (isSubmitting) {
    return (
      <Modal
        show={true}
        backdrop="static"
        fullscreen={true}
        dialogClassName="mx-auto mt-5"
      >
        <div className="d-flex justify-content-center my-5">
          <p className="pt-5 totem-cart-total">Enviando Pedido</p>
        </div>
        <div className="d-flex justify-content-center mb-5">
          <Spinner animation="border my-5 totem-checkout-spinner" />
        </div>
      </Modal>
    );
  }

  return (
    <div className="px-3">
      {isSelfcheckoutTotem && (
        <div className="d-flex mt-5">
          <Button
            className="w-30 mr-2 totem-big-button"
            variant="secondary"
            onClick={onCancel}
          >
            Cancelar Tudo
          </Button>
          <Button
            className="w-100 ml-2 totem-big-button"
            variant="shop"
            onClick={startSteps}
          >
            Finalizar Pedido
          </Button>
        </div>
      )}

      {!isSelfcheckoutTotem && (
        <Button className="w-100 p-2" variant="shop" onClick={startSteps}>
          Finalizar Pedido
        </Button>
      )}
      <Modal
        show={!!step}
        backdrop="static"
        fullscreen={true}
        dialogClassName="checkout-dialog-body"
      >
        <Modal.Body className="checkout-dialog-body">
          <div className="m-5">
            <Table step={step} moveStep={moveStep} />
            <TakingHome step={step} moveStep={moveStep} />
            <PersonalData
              step={step}
              moveStep={moveStep}
              isSelfcheckoutTotem={isSelfcheckoutTotem}
            />
            <CPFQuestion step={step} moveStep={moveStep} />
            <CPFNumber step={step} moveStep={moveStep} />
          </div>
        </Modal.Body>
      </Modal>
      <CheckoutServerError />
    </div>
  );
}

export { TakingHome, PersonalData, CPFQuestion, CPFNumber };
