import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import toPriceDisplay from "../utils/toPriceDisplay";
import useGoTo from "../hooks/useGoTo";
import { Row } from "react-bootstrap";

export default function FloatingCartSummary() {
  const goTo = useGoTo();
  const { commitedOrders, cachedTotalCosts, isSelfcheckoutTotem, inLocoText } =
    useSelector((state) => ({
      isSelfcheckout: state.shop.isSelfcheckout,
      isSelfcheckoutTotem: state.shop.isSelfcheckoutTotem,
      isInLoco: state.shop.isInLoco,
      inLocoText: state.shop.inLocoText,
      commitedOrders: state.shoppingCart.productOrders.filter(
        (o) => o.commited
      ),
      cachedTotalCosts: state.shoppingCart.cachedTotalCosts,
    }));

  const label = inLocoText ? `Ver Sacola (${inLocoText})` : "Ver Sacola";

  if (isSelfcheckoutTotem) {
    const itensLabel =
      commitedOrders.length === 1 ? "1 Item" : `${commitedOrders.length} Itens`;

    return (
      <Row className="p-0 m-0 fixed-bottom">
        <Button
          variant="shop"
          className="w-100 d-flex justify-content-between totem-cart-button totem-big-button"
          onClick={() => goTo("/checkout")}
        >
          <span>{label}</span>
          <span>{itensLabel}</span>
        </Button>
      </Row>
    );
  }

  return (
    <Container className="m-0 p-0 fixed-bottom bg-white">
      <Button
        variant="shop"
        className="w-100 d-flex justify-content-between p-3 rounded-0"
        onClick={() => goTo("/checkout")}
      >
        <span>
          <ShoppingCartIcon /> <small>{commitedOrders.length}</small>
        </span>
        <span>{label}</span>
        <span>{toPriceDisplay(cachedTotalCosts)}</span>
      </Button>
    </Container>
  );
}
