import React from "react";
import { Container, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, Typography } from "@material-ui/core";
import useGoTo from "../../hooks/useGoTo";
import Loading from "../../components/Loading";

const useStyles = makeStyles(() => ({
  divPagamentoSucesso: {
    background: `green`,
    padding: `1rem`,
    color: `white`,
    borderRadius: `1rem`,
  },
  icon: {
    width: 120,
    height: 120,
    marginBottom: `1rem`,
  },
}));

export default function SelfCheckoutTableStatus({
  shortReference,
  order,
  status,
}) {
  const goto = useGoTo();
  const classes = useStyles();

  if (!order || !status) {
    return (
      <Container>
        <Loading message="Carregando Status Autoatendimento Totem..." />
      </Container>
    );
  }

  const handleNovoPedido = () => {
    goto("/");
  };

  return (
    <Container className={`text-center`}>
      <div>
        <div className="d-flex flex-row justify-content-center m-5">
          <Typography variant="h3" className="m-5">
            <strong>Seu pedido foi realizado com sucesso!</strong>
          </Typography>
        </div>
        <div
          className={`d-flex flex-column align-items-center m-5 p-5 ${classes.divPagamentoSucesso}`}
        >
          <CheckCircleIcon className={classes.icon} />
          <Typography variant="h4">
            <strong>Vamos iniciar o preparo agora mesmo!</strong>
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Typography variant="h5">
              Para modificar o pedido ✍🏻 ou pagar a conta 💳
              <br />
              chame um atendente por favor 🙋🏻
            </Typography>
          </Box>
        </div>
        <Button className="mt-5 px-5 py-3" onClick={handleNovoPedido}>
          <Typography variant="h5">Novo Pedido</Typography>
        </Button>
      </div>
    </Container>
  );
}
