import { createSlice } from "@reduxjs/toolkit";
import assignPick from "../utils/assignPick";
import { minimalDelayedHOC } from "../utils/minimalDelayedHOC";
import { API_PALLAS_AUTH_URL } from "./consts";

const initialState = Object.freeze({
  id: 0,
  uri: "",
  name: "",
  phone: "",
  whatsapp: "",
  manualAddressEnable: false,
  addressStreet: "",
  addressNumber: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  postalCode: "",
  pictureUrl: "",
  emergencyPause: null,
  workingScheduleInloco: [],
  workingScheduleDelivery: [],
  workingScheduleToGo: [],
  deliveryFees: [],
  availablePaymentsToGo: [],
  availablePaymentsDelivery: [],
  deliveryEnable: false,
  deliveryMinimalCost: "",
  takeoutEnable: false,
  takeoutDiscount: "",
  informationHeader: "",
  informationFooter: "",
  mercadoPagoKey: "",
  mercadoPagoLogin: false,
  mercadoPagoCartao: false,
  mercadoPagoPix: false,
  pizzaPriceMode: "",
  isAvailableToOrder: false,
  messageToOrder: "Este menu é apenas para visualização.",
  isInLoco: false,
  orderLocation: "",
  tableId: "",
  inLocoCmd: false,
  inLocoTitle: "",
  inLocoText: "",
  inLocoRequireToken: false,
  isInLocoConfirmed: false,
  messageOrderSuccess: "",
  layoutGroupsFirst: false,
  kilogramStep: 0.1,
  isWhatsappSendingEnabled: false,
  user18Older: false,

  inLocoRequirePayment: false,
  presentialPosPayment: false,

  isValidatingToken: false,
  token: "",
  tokenError: "",

  isSelfcheckout: false,
  isSelfcheckoutPOS: false,
  isSelfcheckoutQRCode: false,
  isSelfcheckoutTotem: false,
  isSelfOrderTable: false,

  fidelity: {},
  isOrderSchedulingEnabled: false,
  daysAheadToSchedule: 0,

  backgroudColor: null,
  textColor: null,
  selfcheckoutConfigs: [],
});

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShopDetails: (state, { payload }) => {
      assignPick(state, payload, Object.keys(initialState));

      state.inLocoTitle = state.inLocoCmd ? "Comanda" : "Mesa";
      if (state.isSelfOrderTable) {
        const mesaData = state.tableId.split("-");
        state.inLocoText = state.inLocoTitle + " " + mesaData[1];
      } else if (state.isSelfcheckout) {
        state.inLocoText = null;
      } else if (state.isInLoco) {
        state.inLocoText = Number(state.tableId)
          ? `${state.inLocoTitle} ${state.tableId}`
          : state.tableId;
      }
    },
    setOrderAvailability: (state, { payload }) => {
      state.isAvailableToOrder = !!payload.isAvailableToOrder;
    },
    setInLoco: (state, { payload }) => {
      state.orderLocation = payload.orderLocation;
      state.isInLoco = !!payload.isInLoco;
      if (state.isInLoco && payload.tableId) {
        state.tableId = decodeURI(payload.tableId);
      }

      if (
        payload.orderLocation === "autoatendimento" &&
        payload.tableId === "pos"
      ) {
        //usuário realiza o pedido no cardápio digital exibido no POS
        state.isSelfcheckout = true;
        state.isSelfcheckoutPOS = true;
      }

      if (
        payload.orderLocation === "autoatendimento" &&
        payload.tableId === "qrcode"
      ) {
        //usuário realiza o pedido no próprio celular e paga no POS digitando o número do pedido
        state.isSelfcheckout = true;
        state.isSelfcheckoutQRCode = true;
      }

      if (
        payload.orderLocation === "autoatendimento" &&
        payload.tableId &&
        payload.tableId.startsWith("totem-")
      ) {
        //usuário realiza o pedido na tela touch que está vinculada a um POS
        state.isSelfcheckout = true;
        state.isSelfcheckoutTotem = true;
      }

      if (
        payload.orderLocation === "autoatendimento" &&
        payload.tableId &&
        payload.tableId.startsWith("mesa-")
      ) {
        //usuário realiza o pedido no tablet que está vinculado a mesa, sem pagamento
        state.isSelfcheckout = true;
        state.isSelfcheckoutTotem = true;
        state.isSelfOrderTable = true;
      }
    },
    setInLocoConfirmed: (state, { payload }) => {
      state.isInLocoConfirmed = payload.isInLocoConfirmed;
    },

    setValidatingToken: (state) => {
      state.isValidatingToken = true;
    },
    setValidatingTokenDone: (state, { payload }) => {
      state.isInLocoConfirmed = true;
      state.isValidatingToken = false;
      state.token = payload.token;
    },
    setValidatingTokenError: (state) => {
      state.isValidatingToken = false;
      state.token = initialState.token;
      state.tokenError =
        "Ocorreu um erro ao liberar o cardápio digital. Chame um atendente e escaneie um novo QR Code.";
    },
    setUser18Older: (state, { payload }) => {
      state.user18Older = payload.user18Older;
    },
  },
});

export const inLocoValidateToken =
  (queryToken) => async (dispatch, getState) => {
    const fullState = getState();
    dispatch(shopSlice.actions.setValidatingToken());
    const calmDispatch = minimalDelayedHOC(dispatch);
    const data = {
      lojaId: fullState.shop.id,
      comanda: Number(fullState.shop.tableId),
      token: queryToken,
    };
    try {
      const response = await fetch(API_PALLAS_AUTH_URL, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      if (response.ok) {
        calmDispatch(
          shopSlice.actions.setValidatingTokenDone({ token: json.tokenText })
        );
      } else {
        throw json.error;
      }
    } catch (error) {
      console.log(error);
      calmDispatch(shopSlice.actions.setValidatingTokenError());
    }
  };

export const {
  setShopDetails,
  setOrderAvailability,
  setInLoco,
  setInLocoConfirmed,
  setUser18Older,
} = shopSlice.actions;

export default shopSlice.reducer;
